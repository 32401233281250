export const dictionary = {
  list: '字典列表',
  from: '字典操作',

  parameter_info: '字典数据',

  name: '字典名称',
  title: '字典标题',
  content: '字典内容',
  description: '字典描述',

  rules: {
    name: {
      require: '字典名称不能为空',
      length: '长度在 1 到 20 个字符',
    },
    title: {
      require: '字典标题不能为空',
      length: '长度在 1 到 50 个字符',
    },
    content: {
      require: '字典内容不能为空',
    },
  },

  parameter: {
    list: '字典数据列表',
    from: '字典数据操作',

    title: '字典数据标题',
    value: '字典数据名称',

    rules: {
      title: {
        require: '字典数据标题不能为空',
      },
      value: {
        require: '字典数据内容不能为空',
      },
    }
  }
}
