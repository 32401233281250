export const phrase = {
  list: '资讯短语列表',
  from: '资讯短语操作',
  create: '添加资讯短语',
  view: '查看资讯短语',

  title: '资讯短语标题',
  content: '资讯短语答案',
  content: '资讯短语答案',
  url: '跳转链接',

  rules: {
    title: {
      require: '资讯短语标题不能为空',
    },
    content: {
      require: '资讯短语答案不能为空',
    },
  },
}
