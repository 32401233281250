

/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-11
 *
 * 判断数据类型
 *
 * @return {Boolean} 数据类型
 */
export const isType = (val) => {
  if (val === null)
    return "null";

  if (typeof val !== "object")
    return typeof val;
  else
    return Object.prototype.toString.call(val).slice(8, -1).toLocaleLowerCase();
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-11
 *
 * 获取浏览器默认语言
 *
 * @return {Boolean} 默认语言
 */
export const getBrowserLanguage = () => {
  const language = navigator.language;
  const response = ref("");

  if (language.toLowerCase() === "cn" || language.toLowerCase() === "zh" || language.toLowerCase() === "zh-cn") {
    response.value = "zh";
  } else {
    response.value = "en";
  }

  return response;
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 重置路由地址
 *
 * @param {[type]} url 路由地址
 * @return {Boolean} 重置后路由地址
 */
export function resetRouteUrl(url) {
  if (url.indexOf('/') == 0) {
    return url;
  } else {
    return '/' + url;
  }
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 生成随机数
 *
 * @param {[type]} data 需要判断的数据
 * @param {[type]} data 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function randomNumber(min, max) {
  max = max + 1;

  return Math.floor(Math.random() * (max - min) + min);
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 生成随机字符串验证码
 *
 * @param {[type]} data 字符串原始内容
 * @param {[type]} data 字符串验证码长度
 * @return {Boolean} 判断结果
 */
export function createRandomCode(data, len) {
  const response = ref("");

  for (let i = 0; i < len; i++) {
    response.value += data[randomNumber(0, data.length - 1)];
  }

  return response.value;
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 菜单树形数据转换
 *
 * @param {[type]} data 需要转换的数据
 * @param {[type]} id 子编号
 * @param {[type]} pid 父编号
 * @return {Boolean} 转换结果
 */
export function treeDataTranslate (data, id = 'id', pid = 'parent_id') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }

  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }

  return res
}


/**
 * 获取文件类型
 * @param {*} filename
 */
export function fileType(filename)
{
  // 后缀获取
  var suffix = ''

  // 获取类型结果
  var response = '/src/assets/images/filename/'

  var result = filename.split('.')

  if(result.length > 1)
  {
    suffix = result[result.length - 1]
  }
  else
  {
    response += 'unkown.png'

    return response
  }

  // 图片格式
  var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']

  // 进行图片匹配
  result = imglist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'image.png'

    return response
  }

  // 匹配txt
  var txtlist = ['txt']

  result = txtlist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'txt.png'

    return response
  }
  // 匹配 excel
  var excelist = ['xls', 'xlsx']

  result = excelist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'excel.png'

    return response
  }

  // 匹配 word
  var wordlist = ['doc', 'docx']

  result = wordlist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'word.png'

    return response
  }

  // 匹配 pdf
  var pdflist = ['pdf']

  result = pdflist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'pdf.png'
    return response
  }

  // 匹配 ppt
  var pptlist = ['ppt']

  result = pptlist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'ppt.png'
    return response
  }

  // 匹配 视频
  var videolist = ['mp4', 'm2v', 'mkv']

  result = videolist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'video.png'
    return response
  }

  // 匹配 音频
  var radiolist = ['mp3', 'wav', 'wmv']

  result = radiolist.some(function (item)
  {
    return item == suffix
  })

  if (result)
  {
    response += 'radio.png'
    return response
  }

  // 其他 文件类型
  response += 'unkown.png'

  return response
}
