/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-08
 *
 * 主入口路由(需嵌套上左右整体布局)
 */
import { useStore } from "@/hooks/store/useStore"

export default {
  path: '/',
  name: 'main',
  component: () => import("@/views/layout/index.vue"),
  redirect: { name: 'home' },
  meta: { title: '主入口整体布局' },
  children: [
    {
      path: '/index',
      component: () => import('@/views/index/index.vue'),
      name: 'home',
      meta: { title: '首页' },
    },
    {
      path: '/kernel/user/center',
      component: () => import('@/views/kernel/user/center.vue'),
      name: 'kernel_user_center',
      meta: { title: '用户中心' },
    },
  ],
  beforeEnter(to, from, next) {
    const { base } = useStore();
    // const token = base.token

    let token = base.token
    if (!token || !/\S/.test(token)) {
      next({ name: 'login' })
    }
    next()
  },
}
