export const complain = {
  list: '投诉列表',
  view: '查看投诉',
  read: '标记阅读',

  type: '投诉类型',
  content: '投诉内容',
  nickname: '投诉人昵称',
  mobile: '投诉人电话',
  email: '投诉人邮箱',
  read_status: '阅读状态',
  create_time: '投诉时间',

  // 投诉分类
  category: {
    list: '投诉分类列表',
    from: '投诉分类操作',
    title: '投诉分类标题',

    rules: {
      title: {
        require: '投诉分类标题不能为空',
      },
    },
  },
}
