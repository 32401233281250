// ---------------------------------------
// 核心

import { common } from './system/platform/common/zh'

import { login } from './system/platform/login/zh'

import { user } from './system/platform/user/zh'

import { menu } from './system/platform/menu/zh'

import { route } from './system/platform/route/zh'

import { message } from './system/platform/message/zh'

import { config } from './system/platform/config/zh'

import { dictionary } from './system/platform/dictionary/zh'

import { setting } from './system/platform/setting/zh'

import { log } from './system/platform/log/zh'

// ---------------------------------------
// 模块

import { organization } from './module/platform/organization/zh'

import { member } from './module/platform/member/zh'

import { advertising } from './module/platform/advertising/zh'

import { keyword } from './module/platform/keyword/zh'

import { statistical } from './module/platform/statistical/zh'

import { complain } from './module/platform/complain/zh'

import  {notice}  from './module/platform/notice/zh'

import { problem } from './module/platform/problem/zh'

import { project } from './module/platform/project/zh'

import { demand } from './module/platform/demand/zh'

import { article } from './module/platform/article/zh'

import { phrase } from './module/platform/phrase/zh'

export default {
  // 公共
  common: {
    ...common,
  },

  // 登录
  login: {
    ...login,
  },

  // 用户
  user: {
    ...user,
  },

  // 菜单
  menu: {
    ...menu,
  },

  // 路由
  route: {
    ...route,
  },

  // 消息
  message: {
    ...message,
  },

  // 配置
  config: {
    ...config,
  },

  // 字典
  dictionary: {
    ...dictionary
  },

  // 设置
  setting: {
    ...setting,
  },

  // 日志
  log: {
    ...log,
  },

  // ------------------------------------

  // 广告
  advertising: {
    ...advertising,
  },

  // 机构
  organization: {
    ...organization,
  },

  // 会员
  member: {
    ...member,
  },

  // 关键字
  keyword: {
    ...keyword,
  },

  // 统计
  statistical: {
    ...statistical,
  },

  // 投诉
  complain: {
    ...complain,
  },

  // 通知
  notice: {
    ...notice,
  },

  // 常见问题
  problem: {
    ...problem,
  },

  // 项目
  project: {
    ...project,
  },

  // 需求
  demand: {
    ...demand,
  },

  // 文章
  article: {
    ...article,
  },

  // 短语
  phrase: {
    ...phrase,
  }
}
