/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-08
 *
 * 全局引入element库
 */
import ElementPlus, {ElMessage, ElMessageBox, ElLoading, ElScrollbar} from "element-plus";
import "element-plus/dist/index.css";
import 'element-plus/theme-chalk/display.css'; //引入这个文件
import * as icons from "@element-plus/icons-vue";
import zh from "element-plus/es/locale/lang/zh-cn";

export default {
  install: (app) => {
    app.use(ElementPlus, {
      locale: zh,
      size: "default",
    });

    for (const key in icons) {
      const item = icons[key];
      app.component(item.name, item);
    }

    window.$message = ElMessage;

    window.$messagebox = ElMessageBox;

    window.$loading = ElLoading;

    // app.config.globalProperties.$message = ElMessage
    // app.config.globalProperties.$loading = ElLoading

  },
};

