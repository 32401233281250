/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否为空
 *
 * @param {[type]} data 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function isEmpty(data) {
  if (data == '' || data === '' || data == 0 || data == null || data === undefined || data === 'undefined') {
    return true
  }

  return false
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否为电子邮箱
 *
 * @param {[type]} data 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function isEmail(data) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否为手机号码
 *
 * @param {[type]} data 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function isMobilePhone(data) {
  return /^1[0-9]{10}$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否为电话号码
 *
 * @param {[type]} data 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function isTelephone(data) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否由小写字母组成
 *
 * @param {[type]} path 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function iseLowerCase(data) {
  return /^[a-z]+$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否由大写字母组成
 *
 * @param {[type]} path 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function iseUpperCase(data) {
  return /^[A-Z]+$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否由大小写字母组成
 *
 * @param {[type]} path 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function isAlphabets(data) {
  return /^[A-Za-z]+$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断数据是否由大小写字母与数字组成
 *
 * @param {[type]} path 需要判断的数据
 * @return {Boolean} 判断结果
 */
export function isText(data) {
  return /^[A-Za-z0-9]+$/.test(data)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断路径是否为URL链接
 *
 * @param {[type]} path 需要判断的链接
 * @return {Boolean} 判断结果
 */
export function isURL(path) {
  return /^http[s]?:\/\/.*/.test(path)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断路径是否为网络链接
 *
 * @param {[type]} path 需要判断的链接
 * @return {Boolean} 判断结果
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断文件是否为图片文件
 *
 * @param {[type]} file 需要判断的文件
 * @return {Boolean} 判断结果
 */
export function isPicture(file) {
  return /(.png|.jpg|.jpeg|.gif)/.test(file)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断文件是否为文档文件
 *
 * @param {[type]} file 需要判断的文件
 * @return {Boolean} 判断结果
 */
export function isFile(file) {
  return /(.doc|.docx|.pdf)/.test(file)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断文件是否为音频文件
 *
 * @param {[type]} file 需要判断的文件
 * @return {Boolean} 判断结果
 */
export function isAudio(file) {
  return /(.mp3)/.test(file)
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-09
 *
 * 判断文件是否为视频文件
 *
 * @param {[type]} file 需要判断的文件
 * @return {Boolean} 判断结果
 */
export function isVideo(file) {
  return /(.mp4)/.test(file)
}
