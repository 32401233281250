/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-12
 *
 * 存储组合钩子
 */
import { getBaseInfo } from "@/store/modules/base";
import { getUserInfo } from "@/store/modules/user";
import { getMenuInfo } from "@/store/modules/menu";
import { getSystemInfo } from "@/store/modules/system";
import { getPermissionInfo } from "@/store/modules/permission";


export const useStore = () => {

  /**
   * 基础存储对象
   */
  const base = getBaseInfo();

  /**
   * 用户存储对象
   */
  const user = getUserInfo();

  /**
   * 菜单存储对象
   */
  const menu = getMenuInfo();

  /**
   * 系统存储对象
   */
  const system = getSystemInfo();

  /**
   * 权限存储对象
   */
  const permission = getPermissionInfo();

  return {
    base,
    user,
    menu,
    system,
    permission,
  }
}
