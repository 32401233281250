/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-12
 *
 * 系统语言钩子
 */
import { getBrowserLanguage } from "@/utils/library";

export const useLanguage = (base) => {

  const I18n = useI18n();

  // 无痕切换系统语言
  const autoSwitchLanguage = computed(() => {
    if (base.language && base.language === "zh") {
      I18n.locale.value = "zh";
      return true;
    }
    if (base.language === "en") {
      I18n.locale.value = "en";
      return true;
    }

    // 解决 页面刷新 I18n language 丢失问题
    I18n.locale.value = base.language || getBrowserLanguage();

    base.setLanguage(I18n.locale.value);

    return true;
  });


  return {
    autoSwitchLanguage
  }
}
