export const project = {
  list: '项目列表',
  from: '项目操作',
  view: '项目详情',

  info: '项目信息',
  basic_info: '基本信息',
  detail_info: '详细信息',
  attachment: '附件信息',
  category_info: '项目分类',
  industry_info: '项目行业',
  label_info: '项目标签',
  customer_info: '客户信息',
  leader_info: '项目主管',
  personnel_info: '开发人员',
  resource_info: '资源信息',

  picture: '项目封面',
  type: '平台类型',
  title: '项目名称',
  description: '项目描述',
  content: '项目内容',
  project_date: '立项日期',
  link: '项目地址',
  version: '项目版本',
  create_time: '立项时间',
  type: '项目类型',
  resource: '项目资源',
  read_status: '阅读状态',
  is_hot: '热门标记',

  rules: {
    title: {
      require: '项目名称不能为空',
    },
    picture: {
      require: '项目封面不能为空',
    },
    description: {
      require: '项目描述不能为空',
    },
  },

  // 项目分类
  category: {
    list: '分类列表',
    from: '分类操作',

    title: '分类名称',

    rules: {
      title: {
        require: '分类名称不能为空',
      },
    },
  },

  // 项目行业
  industry: {
    list: '行业列表',
    from: '行业操作',

    title: '行业名称',

    rules: {
      title: {
        require: '行业名称不能为空',
      },
    },
  },

  // 项目标签
  label: {
    list: '标签列表',
    from: '标签操作',

    title: '标签名称',

    rules: {
      title: {
        require: '标签名称不能为空',
      },
    },
  },

  personnel: {
    list: '人员列表',
    from: '编辑人员',
    view: '查看人员',

    info: '人员信息',
    title: '人员姓名',
    position: '职位',
    avatar: '人员头像',
    description: '人员简介',
    sort: '人员排序',
    status: '人员状态',
    create_time: '添加时间',

    rules: {
      title: {
        require: '人员名称不能为空',
      },
      avatar: {
        require: '人员头像不能为空',
      },
    },
  },

  customer: {
    list: '公司列表',
    from: '编辑公司',
    view: '查看公司',

    info: '公司信息',
    title: '公司名称',
    picture: '公司Logo',
    sort: '人员排序',
    create_time: '合作时间',

    rules: {
      title: {
        require: '公司名称不能为空',
      },
      picture: {
        require: '公司Logo不能为空',
      },
    },
  },
}
