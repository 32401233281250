export const log = {
  action: {
    list: '日志列表',
    view: '日志详情',

    username: '操作人',
    operation: '操作行为',
    browser: '浏览器',
    system: '操作系统',
    address: '所在地',
    create_time: '操作时间',
  },

  operate: {
    list: '日志列表',
    view: '日志详情',

    username: '操作人',
    operation: '操作行为',
    browser: '浏览器',
    system: '操作系统',
    address: '所在地',
    create_time: '操作时间',
  },
}
