export const article = {
  list: '文章列表',
  from: '文章操作',
  view: '文章详情',
  category_info: '文章分类',
  label_info: '文章标签',

  info: '文章信息',
  attachment: '附件信息',
  category_info: '文章分类',
  picture: '文章封面',
  title: '文章名称',
  description: '文章描述',
  content: '文章内容',
  create_time: '发布时间',
  resource: '文章资源',

  rules: {
    title: {
      require: '文章名称不能为空',
    },
  },

  // 文章分类
  category: {
    list: '分类列表',
    from: '分类操作',

    title: '分类名称',

    rules: {
      title: {
        require: '分类名称不能为空',
      },
    },
  },
}
