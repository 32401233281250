export const route = {
  list: '路由列表',
  from: '路由操作',

  title: '路由标题',
  content: '路由描述',
  parent_title: '上级路由',
  url: '路由地址',

  rules: {
    title: {
      require: '路由名称不能为空',
      length: '长度在 1 到 50 个字符',
    },
    url: {
      require: '路由地址不能为空',
    },
  },
}
