// ---------------------------------------
// 核心

import { common } from './system/platform/common/en'

import { login } from './system/platform/login/en'

import { user } from './system/platform/user/en'

import { menu } from './system/platform/menu/en'

import { route } from './system/platform/route/en'

import { message } from './system/platform/message/en'

import { config } from './system/platform/config/en'

import { setting } from './system/platform/setting/en'

import { log } from './system/platform/log/en'

// ---------------------------------------
// 模块

export default {
  // 公共
  common: {
    ...common,
  },

  // 登录
  login: {
    ...login,
  },

  // 用户
  user: {
    ...user,
  },

  // 菜单
  menu: {
    ...menu,
  },

  // 路由
  route: {
    ...route,
  },

  // 消息
  message: {
    ...message,
  },

  // 配置
  config: {
    ...config,
  },

  // 设置
  setting: {
    ...setting,
  },

  // 日志
  log: {
    ...log,
  },

  // ------------------------------------

}
