export const demand = {
  list: '需求列表',
  from: '需求操作',
  view: '需求详情',
  category_info: '需求分类',
  label_info: '需求标签',

  info: '需求信息',
  attachment: '附件信息',
  category_info: '需求分类',
  title: '需求名称',
  description: '需求描述',
  content: '需求内容',
  create_time: '发布时间',
  resource: '需求资源',

  rules: {
    title: {
      require: '需求名称不能为空',
    },
  },

  // 需求分类
  category: {
    list: '分类列表',
    from: '分类操作',

    title: '分类名称',

    rules: {
      title: {
        require: '分类名称不能为空',
      },
    },
  },

  // 需求标签
  label: {
    list: '标签列表',
    from: '标签操作',

    title: '标签名称',

    rules: {
      title: {
        require: '标签名称不能为空',
      },
    },
  },
}
