<template>
  <el-config-provider>
    <RouterView></RouterView>
  </el-config-provider>
</template>

<script setup name="app">
  import { useStore } from "@/hooks/store/useStore";
  import { useLanguage } from "@/hooks/language/useLanguage";

  const { base, system } = useStore()

  const { autoSwitchLanguage } = useLanguage(base)

  // 加载系统基本信息
  system.initSystemInfo();

  // 页面刷新
  const refresh = () => {
    base.setIsNeedRefresh(true);
    nextTick(()=>{
      base.setIsNeedRefresh(false);
    });
  }

  provide("refresh", refresh);
</script>
