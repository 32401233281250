/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 菜单信息存储器
 */
export const getMenuInfo = defineStore("getMenuInfo", {
  state: () => ({
    category_id: 0,
    left_menu_status: false,
    navigationlist: [],
    menulist: [],
  }),
  getters: {},
  actions: {
    setCategoryId(category_id) {
      this.category_id = category_id;
    },
    setLeftMenuStatus(left_menu_status) {
      this.left_menu_status = left_menu_status;
    },
    async initNavigationList() {
      if(this.navigationlist.length){
        return
      }
      await $http({
        url: $http.adornUrl(`/user/navigation`),
        method: 'get',
        params: $http.adornParams(),
      }).then(({ data }) => {
        if (data && data.status === 200) {
          this.navigationlist = data.data
        }
      })
    },
    async initMenuList() {
      if(Object.keys(this.menulist).length){
        return
      }
      await $http({
        url: $http.adornUrl(`/user/menu`),
        method: 'get',
        params: $http.adornParams(),
      }).then(({ data }) => {
        if (data && data.status === 200) {
          this.menulist = data.data
        }
      })
    },
    clearData() {
      this.category_id = 0
      this.left_menu_status = false
      this.navigationlist = []
      this.menulist = []
    },
  },
  persist: {
    key: "menuInfoStorage", // 持久化存入的key
    storage: localStorage // 持久化方式
  }
});
