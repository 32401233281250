/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 基本信息存储器
 */
export const getBaseInfo = defineStore("getBaseInfo", {
  state: () => ({
    token: '',
    language: '',
    isNeedRefresh: false,
  }),
  getters: {},
  actions: {
    setToken(token) {
      this.token = token;
    },
    setLanguage(language) {
      this.language = language;
    },
    setIsNeedRefresh(status) {
      this.isNeedRefresh = status;
    },
    clearData() {
      this.token = '';
      this.language = '';
      this.isNeedRefresh = false;
    },
  },
  persist: {
    key: "baseInfoStorage", // 持久化存入的key
    storage: localStorage // 持久化方式
  }
});
