export const problem = {
  list: '常见问题列表',
  from: '常见问题操作',
  create: '添加常见问题',
  view: '查看常见问题',

  title: '常见问题标题',
  content: '常见问题答案',

  rules: {
    title: {
      require: '常见问题标题不能为空',
    },
    content: {
      require: '常见问题答案不能为空',
    },
  },
}
