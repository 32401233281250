/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-08
 *
 * 全局路由
 */
export default [
  {
    path: '/login',
    component: () => import("@/views/login/index.vue"),
    name: 'login',
    meta: { title: '登录' },
  },
  {
    path: '/404',
    component: () => import("@/views/error/404.vue"),
    name: '404',
    meta: { title: '404未找到' },
  },
  {
    path: '/500',
    component: () => import("@/views/error/500.vue"),
    name: '500',
    meta: { title: '500错误' },
  }
]
