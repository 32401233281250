/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-08
 *
 * 全局路由
 */
import mainRoutes from "./mainRoutes"
import globalRoutes from "./globalRoutes"
import { useStore } from "@/hooks/store/useStore"
import { createRouter, createWebHashHistory } from "vue-router"

const modules = import.meta.glob("@/views/**/*.vue");

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({top: 0}),
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes),
})


/**
 * 前置路由守卫
 */
router.beforeEach((to, from, next) => {
  const { base, permission } = useStore();
  const token = base.token

  // 设置网站标题
  setWebTitle(to)

  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  if (router.options.isAddDynamicMenuRoutes || getCurrentRouteType(to, globalRoutes) === 'global') {
    next()
  } else if (token) {
    $http({
      url: $http.adornUrl('/user/router'),
      method: 'get',
      params: $http.adornParams(),
    }).then(({ data }) => {
      if (data && data.status === 200) {
        loadDynamicMenuRoutes(data.data.menu)
        router.options.isAddDynamicMenuRoutes = true
        permission.setPermissionList(data.data.button)
        next({ ...to, replace: true })
      } else {
        permission.clearData()
        next('login')
      }
    }).catch((e) => {
      console.log(`%c${e} 请求菜单列表和权限失败，跳转至登录页！！`, 'color:blue')
      router.push({ name: 'login' })
    })
  } else {
    router.push({ name: 'login' })
  }
})


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 判断当前路由类型
 *
 * @param {[type]} route 当前路由
 * @param {[type]} globalRoutes 公共路由对象
 * @param {Function} global: 全局路由, main: 主入口路由
 */
function getCurrentRouteType(route, globalRoutes = []) {
  var temp = []
  for (var i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global'
    } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
      temp = temp.concat(globalRoutes[i].children)
    }
  }

  return temp.length >= 1 ? getCurrentRouteType(route, temp) : 'main'
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 动态加载菜单路由
 *
 * @param {*} menuList 菜单列表
 * @param {*} routes 递归创建的动态(菜单)路由
 */
function loadDynamicMenuRoutes(menuList = [], routes = []) {
  var temp = []

  for (var i = 0; i < menuList.length; i++)
  {
    if (menuList[i].url && /\S/.test(menuList[i].url))
    {
      const route = {
        path: '/' + menuList[i].url,
        component: modules["/src/views/" + menuList[i].url + ".vue"],
        name: menuList[i].url.replace(/\//g, '_'),
        meta: { title: menuList[i].title },
      }

      routes.push(route)
    }

    if (menuList[i].navigation && menuList[i].navigation.length >= 1)
    {
      temp = temp.concat(menuList[i].navigation)
    }
  }

  if (temp.length >= 1)
  {
    loadDynamicMenuRoutes(temp, routes)
  }
  else
  {
    mainRoutes.name = 'main-dynamic'
    mainRoutes.children.push(...routes)

    for (const item of routes) {
      router.addRoute("main", item);
    }

    console.log('\n')
    console.log('%c!<-------------------- 动态(菜单)路由 s -------------------->', 'color:blue')
    console.log(mainRoutes.children)
    console.log(mainRoutes)
    console.log('%c!<-------------------- 动态(菜单)路由 e -------------------->', 'color:blue')
  }
}


/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 设置网站标题
 *
 * @return {[type]}
 */
function setWebTitle(to) {
  // 动态设置当前页面title
  const title = import.meta.env.VITE_APP_TITLE;

  document.title = to.meta.title ? `${to.meta.title} - ${title}` : title;
}

export default router
