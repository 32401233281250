/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 储器初始化
 */
import piniaPluginPersistedstate from "pinia-plugin-persistedstate" // 持久化

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

export default pinia;
