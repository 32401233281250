/**
 * @author zhangxiaofei [<1326336909@qq.com>]
 * @dateTime 2023-02-13
 *
 * 权限信息存储器
 */
export const getPermissionInfo = defineStore("getPermissionInfo", {
  state: () => ({
    permissionlist: []
  }),
  getters: {},
  actions: {
    setPermissionList(data) {
      this.permissionlist = data;
    },
    clearData() {
      this.permissionlist = []
    },
  },
  persist: {
    key: "permissionInfoStorage", // 持久化存入的key
    storage: localStorage // 持久化方式
  }
});
